import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import dayjs from 'dayjs'

import { Alert, Box, Grid, List, ListItem, Typography } from '@mui/material'
import { environment } from '../../../environment'
import ErrorBox from '../../../components/ErrorBox'
import useFetchLac from '../../hooks/useFetchLac'
import Spinner from '../../components/Spinner'
import { API } from '../api'
import FieldPickupLocation from './FieldPickupLocation'
import FieldInsurancePackage from './FieldInsurancePackage'
import DetailsInsurancePackages from './DetailsInsurancePackages'
import FieldOptions from './FieldOptions'
import Summary from './Summary'
import Price from './Price'
import PickupDateTime from './PickupDateTime'
import { FUEL_CARD, GPS } from './_MOCKED'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../styles/form.css'

const FormOfferOptions = ({ locations, packages, services, processData }) => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const { data, loading, error, fetchData } = useFetchLac()

    const [pickupLocationState, setPickupLocationState] = useState('pickupInExpress')
    const [errorPickupLocation, setErrorPickupLocation] = useState(false)
    const [errorPickupLocationWithoutStreet, setErrorPickupLocationWithoutStreet] = useState(false)
    const [isAutopunkt, setIsAutopunkt] = useState(false)

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        processData && processData?.is_loacation_autopunkt === 'yes' && setIsAutopunkt(true)
        processData && processData?.pickup_location_express_place === 'no'
            ? setPickupLocationState('pickupInAnyLocation')
            : setPickupLocationState('pickupInExpress')
    }, [processData])

    // ===========================================================================================================================

    const defaultRoundedTime = () => {
        const now = dayjs()
        const remainder = 15 - (now.minute() % 15)
        const rounded = now.add(remainder, 'minute').second(0)
        return rounded
    }

    const [pickupDate, setPickupDate] = useState(new Date())

    const [minPickupDate, setMinPickupDate] = useState(new Date())

    const [maxPickupDate, setMaxPickupDate] = useState(new Date())

    const [pickupTime, setPickupTime] = useState(defaultRoundedTime)

    const [pickupDateTime, setPickupDateTime] = useState(
        dayjs(pickupDate)
            .set('hour', dayjs(pickupTime).hour())
            .set('minute', dayjs(pickupTime).minute())
            .set('second', 0)
            .format('YYYY-MM-DD[T]HH:mm:ss')
    )

    useEffect(() => {
        isAutopunkt
            ? //Dla typu punktu odbioru = Autopunkt  : 14 dni (336 h)
              setMinPickupDate(new Date(dayjs().add(336, 'hour')))
            : // Dla typu punktu odbioru = Location :  2 dni (48 h)
              setMinPickupDate(new Date(dayjs().add(48, 'hour')))
    }, [isAutopunkt])

    useEffect(() => {
        // ustaw defaultową datę
        setPickupDate(processData?.pickup_date ? new Date(dayjs(processData?.pickup_date)) : minPickupDate)
        setPickupTime(processData?.pickup_date ? dayjs(processData?.pickup_date) : defaultRoundedTime)
    }, [minPickupDate])

    useEffect(() => {
        // ustaw max datę - 30 dni
        setMaxPickupDate(new Date(dayjs().add(30, 'day')))
    }, [])

    useEffect(() => {
        setPickupDateTime(
            dayjs(pickupDate)
                .set('hour', dayjs(pickupTime).hour())
                .set('minute', dayjs(pickupTime).minute())
                .set('second', 0)
                .format('YYYY-MM-DD[T]HH:mm:ss')
        )
    }, [pickupDate, pickupTime])

    // ===========================================================================================================================

    const initialValues = {
        pickupLocation: pickupLocationState /* pickupInExpress || pickupInAnyLocation */,

        pickupLocationExpress: processData?.pickup_location_express || locations?.[0],
        pickupLocationExpressAddress: processData?.pickup_location_address || locations?.[0]?.name || '',

        pickupTargeoPlace: processData?.pickup_location_targeo_place || {},
        pickupTargeoAddress: processData?.pickup_location_targeo_address || '',

        pickupDateTime: processData?.pickup_date || pickupDateTime,

        options: {
            // fuelCard: processData?.services_with_fuel_card || false,
            // fuelCardId: processData?.services_fuel_card || -1,

            gps: processData?.services_with_gps || false,
            gpsId: processData?.services_gps_id || -1,
        },
    }

    const validationSchema = Yup.object().shape({
        pickupDateTime: Yup.string().required(t('errors.schema.required')),
    })
    
    function getPostcode(str) {
        const postcodeRegex = /\d{2}-\d{3}/;
        const match = str.match(postcodeRegex);
        return match ? match[0] : '';
    }

    const handleSubmit = async values => {
        let data = {
            pickup_date: values.pickupDateTime,
            pickup_location_express: values.pickupLocationExpress || null,
            pickup_location_address: values.pickupLocationExpressAddress || '',
            summary_pickup_place: '',

            insurance_package_id: values.insurancePackage?.id?.toString() || null,
            insurance_package_name: values.insurancePackage?.name?.toString() || '',
            insurance_package_nameEn: values.insurancePackage?.nameEn?.toString() || '',
            insurance_package: values.insurancePackage || null,

            services_fuel_card: values.options.fuelCardId || '-1',
            services_gps: values.options.gpsId || '-1',

            insurance_package_price: values?.insurancePackage?.monthlyFee || 0,
            insurance_package_price_gross: values?.insurancePackage?.monthlyFeeGross || 0,

            services_gps_price: values.options?.gps ? GPS.priceNetto : 0,
            services_gps_price_gross: values.options?.gps ? GPS.priceBrutto : 0,
            services_with_gps: values.options?.gps,
            services_gps_id: services[0]?.id,
            pickup_location_postcode: values.pickupTargeoAddress != "" ? getPostcode(values.pickupTargeoAddress) : (values.pickupLocationExpress?.postCode ? values.pickupLocationExpress?.postCode : ''),
            return_location_postcode: ''

            // services_fuel_card_price: values.options?.fuelCard ? FUEL_CARD.priceNetto : 0,
            // services_fuel_card_price_gross: values.options?.fuelCard ? FUEL_CARD.priceBrutto : 0,
            // services_with_fuel_card: values.options?.fuelCard,
            // services_fuel_card_id: services[1]?.id,
        }

        if (values.pickupLocation === 'pickupInExpress') {
            data = {
                ...data,
                pickup_location_id: values.pickupLocationExpress.id?.toString(),
                pickup_location_targeo_place: {},
                pickup_location_targeo_address: '',
                pickup_location_coord_lon: '',
                pickup_location_coord_lat: '',
                summary_pickup_place: values.pickupLocationExpress.name,
            }
        } else {
            data = {
                ...data,
                pickup_location_id: '-1',
                pickup_location_targeo_place: values.pickupTargeoPlace || {},
                pickup_location_targeo_address: values.pickupTargeoAddress,
                pickup_location_coord_lon: values.pickupTargeoPlace?.lon?.toString(),
                pickup_location_coord_lat: values.pickupTargeoPlace?.lat?.toString(),
                summary_pickup_place: values.pickupTargeoPlace?.label?.split(',').slice(0, 2).join(''),
            }
        }

        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
            console.log('submit data ', data)
        }

        await fetchData(`${API['POST_NEW_DATA']}&pid=${id}`, 'POST', data, locale)
    }

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return (
        locations &&
        packages &&
        processData &&
        !loading && (
            <>
                {/* --------- MIEJSCE ODBIORU AUTA --------- */}
                {!isAutopunkt && (
                    <Typography variant="h2" textAlign="left" fontWeight="bold" fontSize={{ xs: 20, lg: 32 }} mb={0}>
                        {t('LAC.offer_presentation.heading_1')}
                    </Typography>
                )}
                <Formik
                    // enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                        <Form>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={8} mt={4}>
                                    {isAutopunkt ? (
                                        <>
                                            <Typography
                                                variant="h2"
                                                textAlign="left"
                                                fontWeight="bold"
                                                fontSize={{ xs: 20, lg: 32 }}
                                                mb={2}
                                            >
                                                {t('LAC.offer_presentation.heading_1_autopunkt')}
                                            </Typography>
                                            <Box
                                                border="1px solid #d1d1d1"
                                                p={2}
                                                borderRadius="10px"
                                                sx={{
                                                    opacity: 1,
                                                }}
                                            >
                                                <Typography> {t('LAC.offer_presentation.autopunkt')}</Typography>
                                                <Typography fontSize={20} fontWeight="bold">
                                                    {locations[0]?.name}
                                                </Typography>
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <FieldPickupLocation
                                                locations={locations}
                                                setPickupLocationState={setPickupLocationState}
                                                setErrorPickupLocation={setErrorPickupLocation}
                                                setErrorPickupLocationWithoutStreet={setErrorPickupLocationWithoutStreet}
                                            />
                                            {errorPickupLocation && (
                                                <Box my={2}>
                                                    <Alert severity="error">{t('LAC.offer_presentation.heading_1')}</Alert>
                                                </Box>
                                            )}
                                            {errorPickupLocationWithoutStreet && (
                                                <Box my={2}>
                                                    <Alert severity="error">{t('RAC.step_one.label_search_address')}</Alert>
                                                </Box>
                                            )}
                                        </>
                                    )}
                                    {/* --------- DATA ODBIORU AUTA --------- */}
                                    <>
                                        <Typography
                                            variant="h2"
                                            textAlign="left"
                                            fontWeight="bold"
                                            fontSize={{ xs: 20, lg: 32 }}
                                            mt={5}
                                            mb={2}
                                        >
                                            {t('LAC.offer_presentation.heading_2')}
                                        </Typography>
                                        <Typography mb={2}>
                                            {t('LAC.offer_presentation.possible_time.0')}
                                            {isAutopunkt ? ` 14 ${t('days')} ` : ` 2 ${t('days')} (48 h) `}
                                            {t('LAC.offer_presentation.possible_time.1')} 30 {t('days')}
                                        </Typography>
                                        <PickupDateTime
                                            isAutopunkt={isAutopunkt}
                                            processData={processData}
                                            pickupDate={pickupDate}
                                            setPickupDate={setPickupDate}
                                            pickupTime={pickupTime}
                                            setPickupTime={setPickupTime}
                                            pickupDateTime={pickupDateTime}
                                            minPickupDate={minPickupDate}
                                            maxPickupDate={maxPickupDate}
                                        />
                                    </>

                                    {/* --------- PAKIET OCHRONNY --------- */}
                                    <Box my={5}>
                                        <Typography
                                            variant="h2"
                                            textAlign="left"
                                            fontWeight="bold"
                                            fontSize={{ xs: 20, lg: 32 }}
                                            mb={3}
                                        >
                                            {t('LAC.offer_presentation.heading_3')}
                                        </Typography>

                                        <Typography mb={4}>{t('LAC.offer_presentation.packages_info_text')}</Typography>

                                        <FieldInsurancePackage packages={packages} processData={processData} />

                                        <DetailsInsurancePackages />
                                    </Box>
                                    {/* --------- DODATKOWE USŁUGI--------- */}
                                    {services.length > 0 && (
                                        <>
                                            <Box>
                                                <Typography
                                                    variant="h2"
                                                    textAlign="left"
                                                    fontWeight="bold"
                                                    fontSize={{ xs: 20, lg: 32 }}
                                                    mb={3}
                                                >
                                                    {t('LAC.offer_presentation.heading_4')}
                                                </Typography>
                                            </Box>
                                            {/* <FieldOptions
                                                name="options.fuelCard"
                                                label={locale === 'pl' ? services[1]?.name : services[1]?.nameEn}
                                                optionId={services[1]?.id}
                                                processData={processData}
                                            >
                                                <List
                                                    sx={{
                                                        listStyleType: 'disc',
                                                        listStylePosition: 'inside',
                                                        mb: 1,
                                                        '& .MuiListItem-root': {
                                                            display: 'list-item',
                                                            pb: 0,
                                                            pl: 0,
                                                        },
                                                    }}
                                                >
                                                    {locale === 'pl'
                                                        ? services[1]?.text?.map((item, index) => (
                                                              <ListItem key={index}>{item}</ListItem>
                                                          ))
                                                        : services[1]?.textEn?.map((item, index) => (
                                                              <ListItem key={index}>{item}</ListItem>
                                                          ))}
                                                </List>
                                                <Typography fontWeight="bold" mb={1}>
                                                    {t('LAC.offer_presentation.fuel_card_additional')}{' '}
                                                    {services[1]?.oneTimeFee} zł{' '}
                                                </Typography>
                                                <Price
                                                    priceNetto={FUEL_CARD.priceNetto}
                                                    priceBrutto={FUEL_CARD.priceBrutto}
                                                />
                                            </FieldOptions> */}
                                            <FieldOptions
                                                name="options.gps"
                                                label={locale === 'pl' ? services[0].name : services[0].nameEn}
                                                optionId={services[0].id}
                                                processData={processData}
                                            >
                                                {locale === 'pl'
                                                    ? services[0].text?.map((item, index) => (
                                                          <Typography mb={2} mt={1} key={index}>
                                                              {item}
                                                          </Typography>
                                                      ))
                                                    : services[0].textEn?.map((item, index) => (
                                                          <Typography mb={2} mt={1} key={index}>
                                                              {item}
                                                          </Typography>
                                                      ))}
                                                <Typography fontWeight="bold" mb={1}>
                                                    {t('LAC.offer_presentation.activation_fee')}{' '}
                                                    {services[0]?.activationFee} zł{' '}
                                                    <span
                                                        style={{
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        ({services[0]?.activationFeeGross} zł {t('gross')})
                                                    </span>
                                                </Typography>
                                                <Price priceNetto="39" priceBrutto="45" />

                                                {/* <Typography>Opłata aktywacyjna wynosi 99 zł netto + VAT</Typography> */}
                                            </FieldOptions>
                                        </>
                                    )}

                                    {/* <Box
                                    maxWidth="600px"
                                    mt={5}
                                    sx={{
                                        wordBreak: 'normal',
                                        background: '#282828',
                                        color: '#f0f0f0',
                                        padding: 2,
                                        fontSize: '14px',
                                    }}
                                    position="fixed"
                                    top={0}
                                    right={0}
                                >
                                    <pre>{JSON.stringify(values, null, '\t')}</pre>
                                </Box> */}
                                </Grid>
                                {/* PODSUMOWANIE */}
                                <Grid item xs={12} md={4}>
                                    <Typography
                                        variant="h3"
                                        textAlign="left"
                                        fontWeight="bold"
                                        fontSize={{ xs: 18, lg: 24 }}
                                        mb="6px"
                                    >
                                        {t('LAC.offer_presentation.summary_selected_offer')}
                                    </Typography>
                                    <Summary
                                        carNumber={processData?.car_number || 1}
                                        processData={processData}
                                        priceNetto={parseFloat(
                                            processData?.offer_rent_rent_price || processData?.offer_rent_rent_price_1
                                        )}
                                        priceBrutto={parseFloat(
                                            processData?.offer_rent_rentgross || processData?.offer_rent_rentgross_1
                                        )}
                                        // depositPriceNetto={processData?.quote_calculation_requiredPaymentAllCars}
                                        // depositPriceBrutto={processData?.quote_calculation_requiredPaymentAllCars_gross}

                                        insurancePackagePriceNetto={values?.insurancePackage?.monthlyFee}
                                        insurancePackagePriceBrutto={values?.insurancePackage?.monthlyFeeGross}
                                        gpsPriceNetto={values.options?.gps ? GPS.priceNetto : 0}
                                        gpsPriceBrutto={values.options?.gps ? GPS.priceBrutto : 0}
                                        fuelCardPriceNetto={0}
                                        fuelCardPriceBrutto={0}
                                        // fuelCardPriceNetto={values.options?.fuelCard ? FUEL_CARD.priceNetto : 0}
                                        // fuelCardPriceBrutto={values.options?.fuelCard ? FUEL_CARD.priceBrutto : 0}
                                        errorPickupLocation={errorPickupLocation}
                                        errorPickupLocationWithoutStreet={errorPickupLocationWithoutStreet}
                                        serviceName0={services[0]?.name || ''}
                                        serviceName1={services[1]?.name || ''}
                                        isSubmitting={isSubmitting}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </>
        )
    )
}

export default FormOfferOptions
