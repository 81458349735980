import React, { useEffect, useState } from 'react'
import useHostname from '../hooks/useHostname'
import { Alert, Box, Checkbox, Container, FormControlLabel, FormGroup, Typography } from '@mui/material'
import axios from 'axios'
import useApiData from '../hooks/useApiData'
import Spinner from '../../../forms/components/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import { API } from '../../../forms/LAC/api'
import CarDetails from '../components/CarDetails' 
import DetailsTabs from './DetailsTabs'
import StepperLac from '../../../forms/LAC/components/StepperLac'
import PhotoGallery from '../components/PhotoGallery'
import { useTranslation } from 'react-i18next'
import PopupContact from '../../../components/PopupContact'
import TagComp from './TagComp'
import useTags from '../hooks/useTags'
import TagsItems from './TagsItems'
import ShareButton from '../../../forms/LAC/components/ShareButton'
import Recaptcha from '../../../forms/components/Recaptcha' 
import HowItWorks from './HowItWorks/HowItWorks'

const EXCLUDED_OFFERS = [2748, 2742, 2712, 3108, 3109]

const CAR_AMOUNT = [
    {
        id: 1,
        amount: '1',
    },
    {
        id: 2,
        amount: '2',
    },
    {
        id: 3,
        amount: '3',
    },
    {
        id: 4,
        amount: '4',
    },
    {
        id: 5,
        amount: '5',
    },
    {
        id: 6,
        amount: '6',
    },
    {
        id: 7,
        amount: '7',
    },
    {
        id: 8,
        amount: '8',
    },
]

export default function OffersListDetail() {
    const origin = useHostname()
    const [showDialogAccountExist, setShowDialogAccountExist] = useState(false)
    const { id, locale } = useParams()
    const { data, loading, error } = useApiData(`${API.GET_OFFER_BY_ID}/${id}`)
    const [recaptchaToken, setRecaptchaToken] = useState()
    const [kmLimit, setKmLimit] = useState(null)
    const [kmLimitArray, setKmLimitArray] = useState([])
    const [kmLimitExcess, setKmLimitExcess] = useState(null)
    const [carAmount, setCarAmount] = useState(1)
    const [errorStart, setErrorStart] = useState(false)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { tagsData } = useTags()
    const { data: fuelData, loading: fuelLoading, error: fuelError } = useApiData(API.GET_FUEL_TYPES)
    const {
        data: transmissionData,
        loading: transmissionLoading,
        error: transmissionError,
    } = useApiData(API.GET_TRANSMISSION_TYPES)
    const findRentById = (id, isBrutto) => {
        id = parseInt(id)
        const rentItem = data?.rents.find(rent => rent.id === id)
        return rentItem ? (isBrutto ? (rentItem.rentGross * carAmount) : (rentItem.rent * carAmount)) : null
    }

    const findExcessById = (id, isBrutto) => {
        id = parseInt(id)
        const rentItem = data?.rents.find(rent => rent.id === id)
        return rentItem ? (isBrutto ? rentItem.excessGross : rentItem.excess) : null
    }

    useEffect(() => {
        if (data) {
            const rentsAllowanceKm = data.rents
                .filter(rent => rent.months === 12)
                .sort((a, b) => a.allowanceKm - b.allowanceKm);
            setKmLimitArray(rentsAllowanceKm)
            setKmLimit(rentsAllowanceKm[0].id)
            setKmLimitExcess(rentsAllowanceKm[rentsAllowanceKm.length - 1].excess)
        } else {
            document.querySelector('main').style.background = `transparent url('${origin}/images/bg-404.png') no-repeat top center` 
            document.querySelector('main').style.backgroundSize = 'cover' 
            document.querySelector('main').style.height = '60vh'
            document.querySelector('footer').style.backgroundColor = 'white'
            document.querySelector('#header.noAbsoluteHeader').style.borderBottom = 'none'
        }
        return () => {		
            document.querySelector('main') && (document.querySelector('main').style.background = 'none')
            document.querySelector('main') && (document.querySelector('main').style.height = 'auto')
            document.querySelector('#header.noAbsoluteHeader') && (document.querySelector('#header.noAbsoluteHeader').style.borderBottom = '1px solid #ccc')
        }
    }, [data])

    useEffect(() => {
        if(data?.onlineReservation == false) {
            if (window.history?.length && window.history.length > 1 && window.history.state.idx !== 0) {
                navigate(-1)
            } else {
                navigate(window.location.href.includes('/en') ? '/en/car-for-company/flexible-rental/' : '/auto-na-dluzej/wynajem-elastyczny/')
            }
        }
    }, [data?.onlineReservation])

    async function handleStartProcess() {
        if(errorStart) return

        if (!recaptchaToken) return

        setErrorStart(true)
        const rentalPeriod = kmLimitArray?.find(item => item.id === kmLimit).months
        const monthlyKmLimit = kmLimitArray?.find(item => item.id === kmLimit).allowanceKm
        const obj = {
            "offer_id": `${data?.id}`, 
            "car_number": `${carAmount}`,
            "rental_period": `${rentalPeriod}`,
            "monthly_km_limit": `${monthlyKmLimit}`,
            "gRecaptchaResponse": `${recaptchaToken}`
        }
        
        try {
            const response = await axios.post(API['POST_START_PROCESS'], obj)
            const apiData = response.data
            if(apiData.status === 'success') {
                const procId = apiData.id
                const url = window.location.href.includes('/en/') ? `/en/car-for-company/flexible-rental/step-3/${procId}` : `/pl/auto-na-dluzej/wynajem-elastyczny/krok-3/${procId}`
                navigate(url)
            }
            
        } catch (error) {
            if(error.response.data.status === 'error') {
                setErrorStart(false)
            }
        }
    }

    const handleClickAllOffers = () => {
        if (window.history?.length && window.history.length > 1 && window.history.state.idx !== 0) {
            navigate(-1)
        } else {
            navigate(window.location.href.includes('/en') ? '/en/car-for-company/flexible-rental/' : '/auto-na-dluzej/wynajem-elastyczny/')
        }
    }

    if(!data && !loading) {
        return (
            <Container sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                <Box textAlign={{ xs: 'center', lg: 'left' }} py={2} m={{xs: "auto", lg: '0'}}>
                    <Typography color="white" fontWeight="500" fontSize={{ xs: 32, lg: 42 }} my={2}>
                        {t('errors.error_offer_is_no_longer_available')}
                    </Typography>
                    <button className="btn-link" onClick={handleClickAllOffers}>{t('btn.see_all')}</button>
                </Box>
            </Container>
        )
    }

    if (loading) {
        return (
            <Container>
                <Spinner />
            </Container>
        )
    }

    if (error) {
        return (
            <Container>
                <Box my={2}>
                    <Alert severity="error">{t('errors.error_offer_no_details')}</Alert>
                </Box>
            </Container>
        )
    }

    const visibleCars = data?.tagIds.includes(1) ? CAR_AMOUNT.slice(0, data.carsCount) : CAR_AMOUNT.slice(0, 1)
    return (
        <div className="container mx-auto px-5 md:px-8 my-6 offer-list-details">
            {showDialogAccountExist && 
                <PopupContact
                    showAlert={showDialogAccountExist}
                    setShowAlert={setShowDialogAccountExist}
                    dialogTitle={t("LAC.popup_contact.title")}
                    idOffer={id}
                    buttonId="L2W"
                />
            }
            <StepperLac activeStep={2} />
            <Box my={4}>
                <h3 className="c-color2 font-bold text-left mb-2">{data?.name}</h3>
                {data?.carNameDetails && <h4 className="opacity-60 font-bold text-left mb-2">{data?.carNameDetails}</h4>}
                <div className="grid grid-cols-1 xl:grid-cols-[66fr,33fr] gap-10 offer-details-page">
                    <div>
                        <div className="overflow-hidden w-full rounded-2xl mb-10 relative">
                            <PhotoGallery photos={data.photos} />
                            <TagComp item={data} />
                        </div>
                        {data?.tagIds.includes(1) ? //if multi-offer, show text
                            <div className='-mt-8 mb-8 italic'>{t('LAC.offer_list.under_photo')}</div>
                        : null}
                        <div className="border border-[#d1d1d1] rounded-2xl p-8">
                            <h4 className="opacity-80 font-bold text-[25px]">
                                {t('LAC.offer_list.offers_list_detail.car_data')}
                            </h4>
                            {data && (
                                <div className="flex gap-4 flex-wrap flex-shrink-0 mt-4">
                                    <CarDetails
                                        numberDoors={data.numberDoors}
                                        numberSeats={data.numberSeats}
                                        fuel={data.fuel}
                                        transmission={data.transmission}
                                        isElastic={data?.productLine === "2K" ? true : false}
                                        isPromo={data?.tagIds.includes(2) ? true : false}
                                        isSmall
                                        fuelTypes={fuelData}
                                        transmissionArray={transmissionData}
                                        mileageMin={data.mileageMin}
                                        mileageMax={data.mileageMax}
                                        isMultiOffer={data.tagIds.includes(1) ? true : false}
                                    />
                                    {tagsData ? 
                                        <TagsItems tagsData={tagsData} car={data} /> : null
                                    }
                                </div>
                            )}
                            <DetailsTabs equipment={data.equipmentIds} />
                        </div>
                        <div className="border border-[#d1d1d1] rounded-2xl p-8 mt-8">
                        <HowItWorks />
                        </div>
                    </div>
                    <div className="">
                        <div className="border border-[#d1d1d1] rounded-2xl flex flex-col overflow-hidden">
                            <div className="flex flex-col p-8">
                                <h4 className="opacity-80 font-bold text-[25px]">
                                    {t('LAC.offer_list.offers_list_detail.config_title')}
                                </h4>
                                <h5 className="opacity-80 font-bold text-[18px] mt-2">
                                    {t('LAC.offer_list.offers_list_detail.range_title')}
                                </h5>
                                <div className="flex mt-4 overflow-x-auto pb-3">
                                    {kmLimitArray?.map((rent, index) => (
                                        <button
                                            onClick={() => setKmLimit(rent.id)}
                                            key={index}
                                            className={`w-full px-4 py-3 transition border font-semibold h-full cursor-pointer flex items-center justify-center ${kmLimit === rent.id ? 'text-orange-500 border-orange' : 'text-[#0b4185] border-[#0b4185]'} ${index === 0 ? 'rounded-l-[50px]' : ''} 
                        ${index === kmLimitArray.length - 1 ? 'rounded-r-[50px]' : ''} `}
                                        >
                                            {rent?.allowanceKm !== 0 ? rent?.allowanceKm.toLocaleString('pl-PL', { useGrouping: true }) : 'PPM'}
                                        </button>
                                    ))}
                                </div>
                                <h5 className="opacity-80 font-bold text-[18px] mt-3">
                                    {t('LAC.offer_list.offers_list_detail.rent_range')}
                                </h5>
                                <div className="flex mt-4">
                                    <button
                                        className={`w-full px-4 py-3 rounded-[50px] transition border font-semibold h-full cursor-pointer flex items-center justify-center text-orange-500 border-orange`}
                                    >
                                        12 {t('LAC.offer_list.offers_list_detail.rental_period')}
                                    </button>
                                </div>
                                <h5 className="opacity-80 font-bold text-[18px] mt-6">
                                    {t('LAC.offer_list.offers_list_detail.car_count')}
                                </h5>
                                <div className="flex mt-4">
                                {visibleCars.map((car, index) => (
                                    <button
                                        onClick={() => setCarAmount(car.id)}
                                        key={index}
                                        className={`w-full px-4 py-3 transition border font-semibold h-full cursor-pointer flex items-center justify-center ${carAmount === car.id ? 'text-orange-500 border-orange' : 'text-[#0b4185] border-[#0b4185]'} ${index === 0 ? 'rounded-l-[50px]' : ''} 
                                                        ${index === visibleCars.length - 1 ? 'rounded-r-[50px]' : ''} `}
                                    >
                                        {car.amount}
                                    </button>
                                ))}
                                </div>
                                    { false && //TODO ukrycie czasowe
                                        <div className="flex gap-4 flex-wrap flex-shrink-0 mt-8">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={<Checkbox />}
                                                        label={
                                                            <Box fontSize={10}>
                                                                {t('LAC.offer_list.offers_list_detail.add_compare_button')}
                                                            </Box>
                                                        }
                                                        />
                                                </FormGroup>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    label={
                                                        <Box fontSize={10}>
                                                            {t('LAC.offer_list.offers_list_detail.save_later_button')}
                                                        </Box>
                                                    }
                                                />
                                            </FormGroup>
                                        </div>
                                    }
                            </div>
                            <div className="b-color3 p-8">
                                {kmLimit && kmLimitExcess && (
                                    <>
                                        <div>
                                            <h5 className="font-bold text-[18px] mt-2">
                                                {t('LAC.offer_list.offers_list_detail.monthly_rent_title')}
                                            </h5>
                                            <div className="mt-2">
                                                <span className="text-3xl font-bold">
                                                    {kmLimit &&
                                                    <>
                                                        {typeof kmLimit === 'number' ? 
                                                        findRentById(kmLimit).toLocaleString('pl-PL', {
                                                            useGrouping: true,
                                                        }) : kmLimit    
                                                    }
                                                    </>
                                                        }
                                                    <small className="text-lg"> zł</small>
                                                </span>
                                                <span className="uppercase ml-2">
                                                    {t('LAC.offer_list.label_netto_price')}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="text-xs font-bold uppercase">
                                                    {kmLimit &&
                                                    <>
                                                        {typeof kmLimit === 'number' ? 
                                                        findRentById(kmLimit, true).toLocaleString('pl-PL', {
                                                            useGrouping: true,
                                                        }) : null    
                                                    }
                                                    </>
                                                        }
                                                    <small className="text-xs"> zł</small>
                                                </span>
                                                <span className="uppercase ml-2 text-xs">
                                                    {t('LAC.offer_list.label_brutto2_price')}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <h5 className="font-bold text-[18px] mt-2">
                                                {kmLimitArray.find(obj => obj.id === kmLimit).allowanceKm !== 0 ? t('LAC.offer_list.offers_list_detail.excess_title') : t('LAC.offer_list.offers_list_detail.excess_ppm_title')}
                                            </h5>
                                            <div className="mt-2">
                                                <span className="text-xl font-bold">
                                                    {kmLimitExcess && <>
                                                    {typeof kmLimit === 'number' ? findExcessById(kmLimit).toLocaleString() : ''}
                                                    </>}
                                                    <small className="text-sm"> zł</small>
                                                </span>
                                                <span className="text-sm uppercase ml-2">
                                                    {t('LAC.offer_list.label_netto_price2')}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="text-xs font-bold uppercase">
                                                    {/* {findRentById(kmLimit).toLocaleString('pl-PL', { useGrouping: true })} */}
                                                    {kmLimitExcess && <>
                                                    {typeof kmLimit === 'number' ? findExcessById(kmLimit, true).toLocaleString() : ''}
                                                    </>}
                                                    <small className="text-xs"> zł</small>
                                                </span>
                                                <span className="text-xs uppercase ml-2">
                                                    {t('LAC.offer_list.label_brutto2_price')}
                                                </span>
                                            </div>
                                        </div>
                                        {/* <div>
                                            <h5 className="font-bold text-[18px] mt-2">
                                               {t('LAC.reservation_deposit')}
                                                 </h5>
                                            <div className="mt-2">
                                                <span className="text-xl font-bold">
                                                    123
                                                    <small className="text-sm"> zł</small>
                                                </span>
                                                <span className="text-sm uppercase ml-2">
                                                    brutto
                                                </span>
                                            </div>
                                            <div>
                                                <span className="text-xs font-bold uppercase">
                                                    100
                                                    <small className="text-xs"> zł</small>
                                                </span>
                                                <span className="text-xs uppercase ml-2">
                                                    netto
                                                </span>
                                            </div>
                                        </div> */}
                                    </>
                                )}
                                <Recaptcha setRecaptchaToken={setRecaptchaToken} />
                                <div className="mt-4 flex gap-4 flex-wrap flex-shrink-0">
                                    {/* zablokowanie oferty 2748, 2742 i 2712; 3108 i 3109 EXCLUDED_OFFERS */}
                                    <button id="L2" disabled={EXCLUDED_OFFERS.includes(data.id) || (data.carsCount === 0 && data.onlineReservation)} onClick={() => {handleStartProcess()}} 
                                        className={`btn-link b-color1 ${EXCLUDED_OFFERS.includes(data.id) || (data.carsCount === 0 && data.onlineReservation) ? 'grayscale cursor-not-allowed' : ''}`}>{t('LAC.offer_list.button_text')}</button>
                                    <button id="L2Z" className=" btn-link secondary" 
                                        onClick={e => {
                                            e.preventDefault()
                                            setShowDialogAccountExist(true)
                                        }}
                                    >
                                        {t('LAC.offer_list.offers_list_detail.ask_offer_button')}
                                    </button>
                                </div>
                                <div> 
                                    <ShareButton 
                                        subject={locale === 'en' ? `Offer: ${data?.name}` : `Oferta: ${data.name}`}
                                        body={locale === 'en' ? 
                                            `Rent online ${data?.name} in flexible rental: ${window.location.href} or check other available offers. Be guided by convenience`
                                            : 
                                            `Zarezerwuj online ${data?.name} w ofercie wynajmu elastycznego: ${window.location.href} lub sprawdź inne dostępne oferty. Kieruj się wygodą`
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    )
}
