import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'
import dayjs from 'dayjs'

import { Box, Container, Stack } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'

import { API, FORM_KEY } from '../api'
import { environment } from '../../environment'
import useFetch from '../hooks/useFetch'

import { addTargeoScript } from '../../addTargeoScript'
import AlertWindow from '../components/AlertWindow'
import Datepicker from '../../components/Datepicker'
import MuiCheckbox from '../../components/MuiCheckbox'
import MuiTimepicker from '../../components/MuiTimePicker'
import Spinner from '../components/Spinner'
import Stepper from '../components/Stepper'
import Recaptcha from '../components/Recaptcha'

import SearchField from './Step1/SearchField'
import ErrorBox from './ErrorBox'

import 'react-datepicker/dist/react-datepicker.css'
import '../../styles/form.css'

const FormStep1 = params => {
    const { t, i18n } = useTranslation()
    const { id, locale } = useParams()
    const { data, loading, error, fetchData } = useFetch()

    // const defaultTime = dayjs().set('hour', 12).set('minute', 0).set('second', 0).set('millisecond', 0)
    const defaultRoundedTime = () => {
        const now = dayjs()
        const remainder = 15 - (now.minute() % 15)
        // 13h (min 13h)
        const rounded = now.add(13, 'hour').add(remainder, 'minute').second(0);
        return rounded
    }

    const [variables, setVariables] = useState()

    const initialDate = dayjs().hour() >= 11 ? dayjs().add(1, 'day') : dayjs(); // jeżeli jest po godzinie 12 ustawia kolejny dzień
    const [startDate, setStartDate] = useState(new Date(initialDate));
    const [endDate, setEndDate] = useState(new Date(dayjs().add(4, 'day')))

    const [startTime, setStartTime] = useState(defaultRoundedTime)
    const [endTime, setEndTime] = useState(defaultRoundedTime)

    const [pickupDateTime, setPickupDateTime] = useState(
        dayjs(startDate)
            .set('hour', dayjs(startTime).hour())
            .set('minute', dayjs(startTime).minute())
            .set('second', 0)
            .format('YYYY-MM-DD[T]HH:mm:ss')
    )
    const [dropoffDateTime, setDropoffDateTime] = useState(
        dayjs(endDate)
            .set('hour', dayjs(endTime).hour())
            .set('minute', dayjs(endTime).minute())
            .set('second', 0)
            .format('YYYY-MM-DD[T]HH:mm:ss')
    )
    const [editModeReady, setEditModeReady] = useState(false)

    const [showAlertLAC, setShowAlertLAC] = useState(false)
    const [alertLACHasBeenShown, setAlertLacHasBeenShown] = useState(false)

    const [showAlertTraficar, setShowAlertTraficar] = useState(false)
    const [alertTrafficarHasBeenShown, setAlertTrafficarHasBeenShown] = useState(false)

    const [carModel, setCarModel] = useState('')
    const [dataCarClasses, setDataCarClasses] = useState([])
    const [dataCarClassInfo, setDataCarClassInfo] = useState({})
    const [loadingCarClasses, setLoadingCarClasses] = useState(false)
    const [errorCarClasses, setErrorCarClasses] = useState(null)

    const [errorText, setErrorText] = useState('')

    const [recaptchaToken, setRecaptchaToken] = useState()

    const getCarClasses = async () => {
        setLoadingCarClasses(true)
        try {
            fetch(API['GET_CAR_CLASSES'])
                .then(response => response.json())
                .then(data => {
                    setDataCarClasses(data)
                })
        } catch (error) {
            setErrorCarClasses(error)
        } finally {
            setLoadingCarClasses(false)
        }
    }

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    useEffect(() => {
        if (params.edit || params.versionB) {
            const url = `${API[`GET_DATA`]}?pid=${id}`
            fetchData(url, 'GET')

            !params.versionB && setEditModeReady(true)

            addTargeoScript()
        }

        if (params.versionB) {
            getCarClasses()
        }
    }, [id])

    useEffect(() => {
        // znajdz klasę o podanej nazwie (lp_car_class)
        if (dataCarClasses?.length > 0) {
            const carClassInfo = dataCarClasses?.filter(carClass => carClass.name === data?.variables?.lp_car_class)[0]

            setDataCarClassInfo(carClassInfo)
            setCarModel(carClassInfo?.exampleCars[0]?.model)
        }
    }, [dataCarClasses, data])

    useEffect(() => {
        data && !params.versionB && setVariables(data?.variables)
    }, [data])

    useEffect(() => {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('variables', variables)

        if (variables) {
            setStartDate(new Date(variables?.pickup_date))
            setEndDate(new Date(variables?.return_date))
            setStartTime(dayjs(variables?.pickup_date))
            setEndTime(dayjs(variables?.return_date))
        }
    }, [variables])

    useEffect(() => {
        setPickupDateTime(
            dayjs(startDate)
                .set('hour', dayjs(startTime).hour())
                .set('minute', dayjs(startTime).minute())
                .set('second', 0)
                .format('YYYY-MM-DD[T]HH:mm:ss')
        )
    }, [startDate, startTime])

    useEffect(() => {
        setDropoffDateTime(
            dayjs(endDate)
                .set('hour', dayjs(endTime).hour())
                .set('minute', dayjs(endTime).minute())
                .set('second', 0)
                .format('YYYY-MM-DD[T]HH:mm:ss')
        )
    }, [endDate, endTime])

    useEffect(() => {
        const todayDateZeroTime = dayjs().startOf('day')
        const startDateZeroTime = dayjs(startDate).startOf('day')
        const endDateZeroTime = dayjs(endDate).startOf('day')
        const startDateIsToday = startDateZeroTime.isSame(todayDateZeroTime)
        const nowTime = dayjs().startOf('minutes')
        const startTimeFormatted = dayjs(startTime).startOf('minutes')
        const endTimeFormatted = dayjs(endTime).startOf('minutes')
        const startTimeBeforeNow = startTimeFormatted.isBefore(nowTime)

        // dziejszy dzien ale godzina nie moze byc wczesniej niz 'teraz'
        startDateIsToday && startTimeBeforeNow && setStartTime(startTimeFormatted.add(2, 'hour'))
    }, [startDate, startTime])

    useEffect(() => {
        const todayDateZeroTime = dayjs().startOf('day')
        const startDateZeroTime = dayjs(startDate).startOf('day')
        const endDateZeroTime = dayjs(endDate).startOf('day')
        const startDateIsToday = startDateZeroTime.isSame(todayDateZeroTime)
        const nowTime = dayjs().startOf('minutes')
        const startTimeFormatted = dayjs(startTime).startOf('minutes')
        const endTimeFormatted = dayjs(endTime).startOf('minutes')
        const startTimeBeforeNow = startTimeFormatted.isBefore(nowTime)

        // ten sam dzień -> godzina zwrotu : startTime + 2h
        startDateZeroTime.isSame(endDateZeroTime) &&
            (endTimeFormatted.isBefore(startTimeFormatted) || endTimeFormatted.isSame(startTimeFormatted)) &&
            setEndTime(dayjs(startTime).add(2, 'hour'))
    }, [endTime])

    // Alert pokazujemy dopieropo zmianie godziny zwrotu
    useEffect(() => {
        //alert Traficar < 1 dzien - auto na godziny
        if (dayjs(dropoffDateTime).diff(dayjs(pickupDateTime), 'hour') < 24) {
            !alertTrafficarHasBeenShown && setShowAlertTraficar(true)
            setAlertTrafficarHasBeenShown(true)
        } else {
            setShowAlertTraficar(false)
        }

        // pokaz alert że > 29 dni
        if (dayjs(dropoffDateTime).diff(dayjs(pickupDateTime), 'days') >= 29) {
            !alertLACHasBeenShown && setShowAlertLAC(true)
            setAlertLacHasBeenShown(true)
        } else {
            setShowAlertLAC(false)
        }
        // }, [pickupDateTime, dropoffDateTime])
    }, [endTime])

    // const setMinimum25h = () => {
    //     dayjs(dropoffDateTime).diff(dayjs(pickupDateTime), 'hour') < 25 && setEndTime(dayjs(startTime).add(25, 'hour'))
    // }

    // const setMax29Days = () => {
    //     if (dayjs(dropoffDateTime).diff(dayjs(pickupDateTime), 'day') >= 29) {
    //         setEndDate(new Date(dayjs(startDate).add(28, 'day')))
    //     }
    // }
    function getPostcode(str) {
        const postcodeRegex = /\d{2}-\d{3}/;
        const match = str.match(postcodeRegex);
        return match ? match[0] : '';
    }
    const initialValues = {
        startLocationPlace: {
            id: params.id ? params.id : variables?.pickup_location_id,
            label: variables?.pickup_location_address,
            lon: variables?.pickup_location_coord_lon,
            lat: variables?.pickup_location_coord_lat,

        },
        endLocationPlace: {
            id: params.id ? params.id : variables?.return_location_id,
            label: variables?.return_location_address,
            lon: variables?.pickup_location_coord_lon,
            lat: variables?.pickup_location_coord_lat,
        },

        startLocation: params.name ? params.name : variables?.pickup_location_address || '',
        endLocation: params.name ? params.name : variables?.return_location_address || '',

        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,

        theSamePlace:
            (variables?.pickup_location_id === variables?.return_location_id ||
            variables?.pickup_location_address === variables?.return_location_address) &&
            !window.location.href.includes('RAC/step1') || false,
    }

    const validationSchema = Yup.object().shape({
        startLocation: Yup.string().required(t('errors.schema.required')),
        endLocation: Yup.string().when('theSamePlace', {
            is: value => value === false,
            then: schema => schema.required(t('errors.schema.required')),
            otherwise: schema => schema.optional(),
        }),
        theSamePlace: Yup.boolean().optional(),
        startDate: Yup.string().required(t('errors.schema.required')),
        endDate: Yup.string().required(t('errors.schema.required')),
        startTime: Yup.string().required(t('errors.schema.required')),
        endTime: Yup.string().required(t('errors.schema.required')),
    })

    const submitValues = async values => {
        setErrorText('')
        let isAlertWithError = false

        // recaptcha tylko dla register
        if (!recaptchaToken && !params.edit && !params.versionB) {
            setErrorText(t('errors.try_again'))
            isAlertWithError = true
            return
        }

        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName))
            console.log('values', values)

        // dla wybranego dowolnego adresu jest ustawione id najbliższego punktu express (przy tworzeniu procesu)
        // czyli przy edycji trzeba dodatkowo sprawdzić czy rzeczywiscie był wybrany punkt expressu (pickup_location_express_place, return_location_express_place)
        // =>>>  trzeba wykryć zmianę, czy przy edycji id punktu się zmieniło
        const pickup_location_express_place =
            (!params.edit && values.startLocationPlace.id) ||
            (params.edit && values.startLocationPlace.id && variables?.pickup_location_express_place === 'yes') ||
            (params.edit &&
                values.startLocationPlace.id &&
                variables?.pickup_location_express_place === 'no' &&
                parseInt(values.startLocationPlace.id, 10) !== parseInt(variables.pickup_location_id, 10))
                ? true
                : false

        const return_location_express_place =
            (!params.edit && values.endLocationPlace.id) ||
            (params.edit && values.endLocationPlace.id && variables?.return_location_express_place === 'yes') ||
            (params.edit &&
                values.endLocationPlace.id &&
                variables?.return_location_express_place === 'no' &&
                parseInt(values.endLocationPlace.id, 10) !== parseInt(variables.return_location_id, 10)) ||
            (pickup_location_express_place && values.theSamePlace)
                ? true
                : false

        // const pickupLocationName =  values?.startLocationPlace?.name?.replace(' - 24h', '').replace(' 24h', '').replace(' - 24 h', '') || ''
        // const returnLocationName =  values.theSamePlace ? pickupLocationName : values?.endLocationPlace?.name?.replace(' - 24h', '').replace(' 24h', '').replace(' - 24 h', '') || ''

        const pickupLocationName = pickup_location_express_place
            ? values?.startLocationPlace?.name?.replace(' - 24h', '').replace(' 24h', '').replace(' - 24 h', '')
            : ''

        let returnLocationName = ''

        if (return_location_express_place) {
            returnLocationName = values.theSamePlace
                ? pickupLocationName
                : values?.endLocationPlace?.name?.replace(' - 24h', '').replace(' 24h', '').replace(' - 24 h', '') || ''
        }
        
        let data = {
            gRecaptchaResponse: recaptchaToken,

            pickup_date: pickupDateTime,
            return_date: dropoffDateTime,

            pickup_location_id: '',
            return_location_id: '',

            pickup_location_street: '',
            return_location_street: '',

            pickup_location_address: '',
            return_location_address: '',

            pickup_location_name: pickupLocationName,
            return_location_name: returnLocationName,

            pickup_location_postcode: getPostcode(values.startLocation) != '' ? getPostcode(values.startLocation) : values?.startLocationPlace?.postCode,
            return_location_postcode: ''
        }

        if (values.theSamePlace) {
            data = {
                ...data,
                return_location_postcode: getPostcode(values.startLocation) != '' ? getPostcode(values.startLocation) : values?.startLocationPlace?.postCode
            }
        }
        else {
            data = {
                ...data,
                return_location_postcode: getPostcode(values.endLocation) != '' ? getPostcode(values.endLocation) : values?.endLocationPlace?.postCode
            }
        }
        if (pickup_location_express_place & return_location_express_place) {
            data = {
                ...data,
                pickup_location_id: values.startLocationPlace.id.toString(),
                return_location_id: values.theSamePlace
                    ? values.startLocationPlace.id.toString()
                    : values.endLocationPlace.id.toString(),
            }
        } else if (pickup_location_express_place & !return_location_express_place) {
            data = {
                ...data,
                pickup_location_id: values.startLocationPlace.id.toString(),
                return_location_address: values.endLocationPlace.label,
                return_location_coord_lon: values.endLocationPlace.lon.toString(),
                return_location_coord_lat: values.endLocationPlace.lat.toString(),
            }
        } else if (!pickup_location_express_place & return_location_express_place) {
            data = {
                ...data,
                pickup_location_address: values.startLocationPlace.label,
                pickup_location_coord_lon: values.startLocationPlace.lon.toString(),
                pickup_location_coord_lat: values.startLocationPlace.lat.toString(),
                return_location_id: values.theSamePlace
                    ? values.startLocationPlace.id.toString()
                    : values.endLocationPlace.id.toString(),
            }
        } else if (!pickup_location_express_place & !return_location_express_place) {
            data = {
                ...data,
                pickup_location_address: values.startLocationPlace.label,
                pickup_location_coord_lon: values.startLocationPlace.lon.toString(),
                pickup_location_coord_lat: values.startLocationPlace.lat.toString(),
                return_location_address: values.theSamePlace
                    ? values.startLocationPlace.label
                    : values.endLocationPlace.label,
                return_location_coord_lon: values.theSamePlace
                    ? values.startLocationPlace.lon.toString()
                    : values.endLocationPlace.lon.toString(),
                return_location_coord_lat: values.theSamePlace
                    ? values.startLocationPlace.lat.toString()
                    : values.endLocationPlace.lat.toString(),
            }
        }

        if (values.theSamePlace && data.pickup_location_id === '') {
            data = {
                ...data,
                return_location_id: '',
                return_location_address: data?.pickup_location_address || '',
                return_location_coord_lon: data?.pickup_location_coord_lon || '',
                return_location_coord_lat: data?.pickup_location_coord_lat || '',
            }
        }

        if (!pickup_location_express_place && (!data.pickup_location_coord_lon || !data.pickup_location_coord_lat)) {
            setErrorText(t('errors.error_validation'))
            isAlertWithError = true
        }

        if (!return_location_express_place && (!data.return_location_coord_lon || !data.return_location_coord_lat)) {
            setErrorText(t('errors.error_validation'))
            isAlertWithError = true
        }

        if (
            (!pickup_location_express_place && values.startLocationPlace.number === '') ||
            (!return_location_express_place && values.endLocationPlace.number === '')
        ) {
            setErrorText(t('RAC.step_one.label_search_address'))
            isAlertWithError = true
        }

        // if (dayjs(dropoffDateTime).diff(dayjs(pickupDateTime), 'hour') < 24) {
        // Dla okresów wynajmu krótszych niż 24 h rekomendujemy Traficar i uniemożliwiamy złożenie rezerwacji
        // setErrorText(t('errors.error_validation_24h')

        // Minimalna długość wynajmu to 2 godziny. Wybierz inną godzinę wydania lub zwrotu samochodu.
        if (dayjs(dropoffDateTime).diff(dayjs(pickupDateTime), 'm') < 120) {
            setErrorText(t('errors.error_validation_min_2h'))
            isAlertWithError = true
        } else if (dayjs(pickupDateTime).diff(dayjs(), 'hour') < 13) {
            // najwcześniej 13h od teraz
            // Godzina odbioru auta nie może być wcześniejsza niż 13 h od godziny / daty systemowej
            setErrorText(t('errors.error_validation_13h'))
            isAlertWithError = true
        } else if (dayjs(dropoffDateTime).diff(dayjs(pickupDateTime), 'hour') < 0) {
            // Data zwrotu auta nie może być wcześniejsza niż data odbioru auta
            setErrorText(t('errors.error_validation_date'))
            isAlertWithError = true
        }

        if (!isAlertWithError) {
            if (editModeReady) {
                await fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=next`, 'POST', data, locale)
            } else if (params.versionB) {
                data = {
                    ...data,
                    model: carModel?.toString() || dataCarClassInfo.name,
                    car_class_name: dataCarClassInfo?.name || '',
                    seats: dataCarClassInfo?.seats || '',
                    fuel: dataCarClassInfo?.fuel || '',
                    transmission: dataCarClassInfo?.transmission || '',
                }
                await fetchData(`${API['SAVE_DATA']}?pid=${id}&navigation=next`, 'POST', data, locale)
            } else {
                await fetchData(API['REGISTER'], 'POST', data, params.locale)
            }
        }
    }

    loading && <Spinner />

    return (
        <Container disableGutters>
            <Formik
                enableReinitialize={editModeReady}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={values => {
                    submitValues(values)
                }}
            >
                {({ isSubmitting, values, errors, touched, setFieldValue }) => (
                    <Form noValidate className="h-full">
                        <div className="p-5 lg:p-10 lg:pb-14 relative h-full">
                            {!params.edit ? (
                                <h2 className="text-orange-500 font-bold text-4xl lg:text-[44px] leading-tight mb-5">
                                    {t('RAC.step_one.heading')}
                                </h2>
                            ) : (
                                <Box mb={4}>
                                    <Stepper activeStep={1} />
                                </Box>
                            )}
                            {(isSubmitting && !errorText && !error) || loading ? (
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <Spinner size={60} mt={5} />
                                </Box>
                            ) : (
                                <>
                                    {(errorText || error) &&  <Stack spacing={2} mt={1} mb={4}>
                                        {errorText && <ErrorBox error={errorText} />}
                                        {!errorText && error && <ErrorBox error={error} />}
                                    </Stack>}

                                    <SearchField
                                        name="startLocation"
                                        placeholder={t('RAC.step_one.label_searchfield')}
                                        errors={errors['startLocation']}
                                        mb="12px"
                                        locale={params.locale}
                                    />

                                    <MuiCheckbox
                                        name="theSamePlace"
                                        label={t('RAC.step_one.label_checkbox')}
                                        errors={errors['theSamePlace']}
                                        touched={touched['theSamePlace']}
                                        style={{ paddingLeft: '8px' }}
                                        onChange={e => {
                                            setFieldValue('theSamePlace', e.target.checked)
                                            e.target.checked &&
                                                setFieldValue('endLocationPlace', values.startLocationPlace)
                                            e.target.checked && setFieldValue('endLocation', values.startLocation)
                                        }}
                                    />

                                    {!values.theSamePlace && (
                                        <Box mb="4px">
                                            <SearchField
                                                name="endLocation"
                                                placeholder={t('RAC.step_one.label_searchfield_dropoff')}
                                                errors={errors['endLocation']}
                                                locale={params.locale}
                                            />
                                        </Box>
                                    )}
                                    <Box
                                        sx={{
                                            height: '56px',
                                            border: '1px solid #d1d1d1',
                                            borderRadius: '50px',
                                            padding: '10px 20px',
                                            mb: '20px',
                                        }}
                                    >
                                        <Stack direction="row">
                                            <Box width="50%">
                                                <Datepicker
                                                    name="startDate"
                                                    selected={startDate}
                                                    onChange={date => setStartDate(date)}
                                                    selectsStart
                                                    startDate={startDate}
                                                    minDate={new Date(dayjs().add(13, 'hour'))}
                                                    endDate={endDate}
                                                    locale={locale}
                                                />
                                            </Box>
                                            <Box width="50%" borderLeft="1px solid #d1d1d1" pl={2}>
                                                <MuiTimepicker
                                                    name="startTime"
                                                    defaultValue={startTime}
                                                    value={startTime}
                                                    onChange={time => setStartTime(time)}
                                                    required
                                                />
                                            </Box>
                                        </Stack>
                                    </Box>

                                    <Box
                                        sx={{
                                            height: '56px',
                                            border: '1px solid #d1d1d1',
                                            borderRadius: '50px',
                                            padding: '10px 20px',
                                        }}
                                    >
                                        <Stack direction="row">
                                            <Box width="50%">
                                                <Datepicker
                                                    name="endDate"
                                                    selected={endDate}
                                                    onChange={date => setEndDate(date)}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={new Date(dayjs().add(26, 'hour'))}
                                                    locale={params.locale || locale}
                                                />
                                            </Box>
                                            <Box width="50%" borderLeft="1px solid #d1d1d1" pl={2}>
                                                <MuiTimepicker
                                                    name="endTime"
                                                    defaultValue={endTime}
                                                    value={endTime}
                                                    onChange={time => setEndTime(time)}
                                                    required
                                                />
                                            </Box>
                                        </Stack>
                                    </Box>

                                    <Recaptcha setRecaptchaToken={setRecaptchaToken} />

                                    <Box textAlign="center" py={2}>
                                        {!loading ? (
                                            <button
                                                id="R1"
                                                type="submit"
                                                disabled={loading}
                                                className="btn-link md:absolute md:bottom-[-35px] md:left-1/2 md:translate-x-[-50%]"
                                            >
                                                <SearchOutlinedIcon style={{ marginRight: '8px' }} />
                                                {t('btn.search')}
                                            </button>
                                        ) : (
                                            <Spinner size={20} sx={{ m: 0, p: 0 }} />
                                        )}
                                    </Box>
                                </>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>

            {showAlertLAC && (
                <AlertWindow
                    showAlert={showAlertLAC}
                    setShowAlert={setShowAlertLAC}
                    dialogTitle={t('RAC.step_one.alert_LAC')}
                    textButton={t('RAC.step_one.see_more')}
                    links={{ en: environment.secondEnMainPage, pl: environment.secondMainPage }}
                    line={params.line}
                    locale={params.locale || locale}
                    // setMinimum25h={setMinimum25h}
                    // setMax29Days={setMax29Days}
                />
            )}

            {showAlertTraficar && (
                <AlertWindow
                    showAlert={showAlertTraficar}
                    setShowAlert={setShowAlertTraficar}
                    dialogTitle={t('RAC.step_one.alert_traficar')}
                    textButton={t('RAC.step_one.see_more')}
                    links={{ en: 'https://traficar.pl/en/', pl: 'https://traficar.pl/' }}
                    line={params.line}
                    locale={params.locale || locale}
                    target="_blank"
                    rel="noopener"
                    // setMinimum25h={setMinimum25h}
                    // setMax29Days={setMax29Days}
                />
            )}
        </Container>
    )
}

export default FormStep1
